.footer {
    background-color: #05476C;
    color: white;
    padding: 40px 30px;
    text-align: center;
    bottom:0;
  }
  
  .footer-about, .footer-links, .footer-social {
    margin-bottom: 30px;
  }
  
  .footer h4 {
    font-weight: bold;
    color:  #E6B422;
    margin-bottom: 15px;
  }
  
  .footer p {
    font-size: 0.95rem;
    line-height: 1.5;
  }
  
  .footer-links ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-links ul li {
    margin-bottom: 10px;
  }
  
  .footer-links ul li a {
    color: white;
    text-decoration: none;
  }
  
  .footer-links ul li a:hover {
    color:  #E6B422;
  }
  
  .social-icons a {
    color: white;
    margin-right: 15px;
  }
  
  .social-icons a:hover {
    color:  #E6B422;
  }
  
  .footer-bottom {
    margin-top: 20px;
    font-size: 0.85rem;
    border-top: 1px solid #ffffff33;
    padding-top: 10px;
  }
  
  .powered-by {
    margin-top: 10px;
  }
  
  .powered-by a {
    color: #E6B422;
    text-decoration: none;
    font-weight: bold;
  }
  
  .powered-by a:hover {
    color: white;
  }
  

/* 
   .footer {
    background-color: #05476C;
    color: white;
    padding: 40px 0;
  }
  
  .footer-sections {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .footer-column {
    flex: 1;
    min-width: 150px;
    margin: 10px;
  }
  
  .footer-column h4 {
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .footer-column ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-column li {
    margin-bottom: 10px;
  }
  
  .footer-column a {
    color: white; 
    text-decoration: none; 
  }
  
  .footer-column a:hover {
    text-decoration: underline;
  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 20px;
  }
  
  .footer-bottom p {
    margin: 0;
  }
  
  .footer-policies {
    margin-top: 10px;
  }
  
  .footer-policies span {
    margin: 0 5px;
  }
   */