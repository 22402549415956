/* About.css */

.about-section {
  padding: 20px;
  text-align: center;
  background-color: #f9f9f9;
}

.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.about-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap:4rem;
  height: 100vh;
}

.about-image {
  max-width: 300px;
  width: 80%;
  height: auto;
  border-radius: 10px;
  margin: 20px;
}

.about-text {
  max-width: 600px;
  padding: 20px;
  font-size: 18px;
  line-height: 1.6;
  color: #333;
  text-align: left; 
}

.mission-vision-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.mission, .vision {
  text-align: left;
  max-width: 600px;
  margin: 20px;
}


.ouricon {
  font-size: 50px;
  color:  #E6B422; 
  margin-bottom: 10px;
}

.ourh2 {
  color:  #05476C;
  font-size: 3rem;
  margin-bottom: 20px;
  font-weight: 500;
  text-align: start;
}


.team {
  display: flex;
  flex-wrap: wrap; /* Make it responsive by allowing wrap */
  justify-content: center;
  gap: 2rem; /* Adjust gap for better spacing */
  padding: 20px; /* Add some padding around the team section */
  margin: 0 auto; /* Center the section on the page */
}

.team .teamitem {
  background-color: white; /* Use a more subtle green */
  padding: 20px;
  border-radius: 15px;
  text-align: center; /* Center text */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add smooth hover effects */
  width: 280px; /* Control width to make it consistent */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Add shadow for better design */
}

.team .teamitem:hover {
  transform: translateY(-10px); /* Add a subtle hover effect */
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2); /* Elevate on hover */
}

.team .teamitem .team-image {
  height: 250px;
  width: 100%; /* Make the image take full width */
  object-fit: cover; /* Ensure images are well-fitted */
  border-radius: 10px; /* Round image corners for a cleaner look */
  margin-bottom: 15px; /* Add spacing below the image */
}

.team .teamitem .role, .team .teamitem .teamname {
  font-size: 22px; /* Adjust font size for better balance */
  font-weight: bold; /* Make the text bolder */
  color: black; /* Make text white to contrast the green background */
}

.team .teamitem .teamname {
  margin-top: 10px;
  font-size: 23px;
  color: #05476C;
}

@media (max-width: 768px) {
  .about-section {

  }
  .about-content{
    height: auto;
    gap:1rem;
  }
  
.ouricon {
  font-size: 40px;
  color:  #E6B422; 
  margin-bottom: 10px;
}

.ourh2 {
  color:  #05476C;
  font-size: 2rem;
  margin-bottom: 20px;
  font-weight: 500;
  text-align: start;
}
  .values-image{
    display:none;
  }

  .team{
    margin: 0;
    padding: 5px 0;
  }
  .teamitem{
    padding: 0;
  }
}
