
/* Header.css */
.Header {
  position: sticky;
  top: 0;
  z-index: 100;
  transition: background 0.3s ease; /* Smooth transition for background change */
  background-color: white;
  
}

.Header.scrolled {
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Adjust shadow as needed */
}
.Header.scrolled .logo {
  height: 70px;
}
.navbar {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.navbar-logo .logo {
  height: 80px;
  margin-left: 60px;
  transition: ease-out 0.3s;
}

.alllinks {
  display: flex; 
  margin-right: 40px;
  justify-content: space-between;
  align-items: center;
}

.navbar-items {
  display: flex;
  list-style-type: none;
  padding: 10px 0; 
  margin: 0 10px;
  justify-content: space-between;
  gap: 3rem;
}

.navbar-item {
  position: relative;
}

.navbar-item .link-flash {
  color: black;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  position: relative;
  --ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
}

.link-flash::before,
.link-flash::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: -0.1rem;
  display: block;
  width: 100%;
  height: 2px;
  background: #E6B422;
  transition: 1.1s var(--ease-out-expo);
}

.link-flash::before {
  transform: scaleX(0);
  transform-origin: left;
}

.link-flash::after {
  transform-origin: right;
  transition-delay: 0.25s;
}

.link-flash:hover::before {
  transform: scaleX(1);
  transition-delay: 0.25s;
}

.link-flash:hover::after {
  transform: scaleX(0);
  transition-delay: 0s;
}
.btndiv{
  margin-right: 35px;
}
.contactlink{
  text-decoration: none;
}
.contactbtn {
  font-size: 18px;
 border-radius: 8px; 
 padding: 8px 30px;
 background-color:  #E6B422;
 color:white;
 font-weight: 400;
  
 }
 
 
 .donate:hover {
  
   color: white;
   background-color:rgb(4, 75, 4);
 }
 

/* Mobile Specific Styles */
.mobile-icon {
  display: none; /* Hide by default */
}

/* Media Queries for Mobile View */
@media (max-width: 768px) {
  .navbar-items{
    display: none; /* Hide navigation items on mobile */
  }
  .navbar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .contactbtn{
    display: none;
  }
  .mobile-icon {
    display:block; /* Show mobile icon on mobile */
    
    margin-right: 20px;
  }

  .navbar-logo {
   margin: 0;
   width: 15%;
  }
  .navbar-logo .logo {
    height: 70px;
    margin-left: 5px;
  }
  .Header.scrolled .logo {
    height: 60px;
  }
}

