.notfound-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    background-color: #f8f9fa;
  }
  
  .notfound-icon {
    font-size: 4rem; /* Adjust size as needed */
    color: #E6B422; /* Gold accent for the icon */
    margin-bottom: 1rem;
  }
  
  .notfound-title {
    font-size: 6rem;
    font-weight: bold;
    color: #05476C; /* Your theme color */
  }
  
  .notfound-message {
    font-size: 1.5rem;
    color: #333;
    margin: 1rem 0;
  }
  
  .notfound-button {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    color: white;
    background-color: #05476C;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .notfound-button:hover {
    background-color: #E6B422; /* Gold accent color on hover */
  }
  
  /* Responsive styling */
  @media (max-width: 768px) {
    .notfound-icon {
      font-size: 3rem;
    }
  
    .notfound-title {
      font-size: 4rem;
    }
  
    .notfound-message {
      font-size: 1.2rem;
    }
  
    .notfound-button {
      padding: 0.5rem 1rem;
      font-size: 0.9rem;
    }
  }
  