/* Container styles for Read More button */
.read-more-container {
    display: inline-block;
    font-family: 'Poppins', sans-serif;
    font-size: 16px; /* Adjust font-size if needed */
  }
  
  /* General link styling */
  .read-more-link {
    overflow: hidden;
    position: relative;
    display: inline-flex;
    align-items: center; /* Align text and icon vertically */
    text-decoration: none;
    color: #18272F;
    font-weight: 700;
    vertical-align: top;
  }
  
  /* Before and After pseudo-elements */
  .read-more-link::before,
  .read-more-link::after {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
  }
  
  .read-more-link::before {
    background-color:#05476C;
    height: 2px;
    bottom: 0;
    transform-origin: 100% 50%;
    transform: scaleX(0);
    transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
  }
  
  .read-more-link::after {
    content: attr(data-replace);
    height: 100%;
    top: 0;
    transform-origin: 100% 50%;
    transform: translate3d(200%, 0, 0);
    transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
    color: #05476C;
  }
  
  /* Hover states */
  .read-more-link:hover::before {
    transform-origin: 0% 50%;
    transform: scaleX(1);
  }
  
  .read-more-link:hover::after {
    transform: translate3d(0, 0, 0);
  }
  
  .read-more-link span {
    display: inline-block;
    transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
  }
  
  .read-more-link:hover span {
    transform: translate3d(-200%, 0, 0);
  }
  
  /* Styling the icon */
  .read-more-icon {
    margin-left: 8px; /* Space between text and icon */
    transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
  }
  
  .read-more-link:hover .read-more-icon {
    transform: translate3d(200%, 0, 0); /* Move the icon with the text */
  }
  