

.carousel-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 40px 0;
  position: relative;
}

.carousel-item {
  text-align: center;
  padding: 20px;
  margin: 18px 20px;
  background: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.carousel-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}
.carousel-text h2{
  margin-top: 15px;
  color:  #E6B422;
  font-size: 1.1rem;
}
.carousel-text p {
  margin-top: 15px;
  color: #333;
  font-size: 1rem;
}

.slick-prev,
.slick-next {
  background: none;
  border: none;
  font-size: 2rem;
  z-index: 1;
  color: #333;
}

.slick-prev:before,
.slick-next:before {
  display: none; /* Hide default arrows */
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.carousel-button:hover {
  background: rgba(255, 255, 255, 1);
}

.carousel-prev {
  left: -25px;
}

.carousel-next {
  right: -25px;
}

/* Mobile responsiveness */
@media screen and (max-width: 768px) {
  .carousel-container {
    max-width: 100%; /* Make full-width on mobile */
    padding: 20px 0;
  }

  .carousel-item {
    padding: 15px;
  }

  .carousel-prev {
    left: -15px;
  }

  .carousel-next {
    right: -15px;
  }

  .carousel-button {
   display: none;
  }
}
