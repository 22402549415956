.hamburger-icon {
  cursor: pointer;
  color: #333;
  transition: transform 0.3s ease-in-out;
  
}
.closebtn{
  margin-top: 24px;
  margin-right: 19px;
}
.hamburger-icon:hover {
  transform: scale(1.2);
}
.wholenav{
  background-color: #E6B422;
}
.mobile-nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
 
}

.mobile-nav-links {
  list-style: none;
  padding: 0;
}

.nav-item {
  margin-bottom: 1.5rem;
  position: relative;
}

.nav-item .link {
  color: #05476C;
  text-decoration: none;
  font-size: 2rem;
  font-weight: bold;
  display: block;
  padding: 0.5rem 0;
  transition: color 0.3s ease-in-out;
  margin-left: 30px;
}

.nav-item .link:hover {
  color: #E6B422; /* Matches theme */
}

/* Add dividers (bottom border) */
.nav-item:not(:last-child) {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1); /* Light gray divider */
  padding-bottom: 1rem;
}

.social-icons {
  margin-top: 2rem;
  text-align: center;
}

.social-icons h5 {
  margin-bottom: 1rem;
  color: #333;
  font-size: 1.2rem;
}

.icons a {
  margin: 0 10px;
  color: #333;
  transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.icons a:hover {
  color: #E6B422; /* Matches theme */
  transform: scale(1.2);
}
