.hero-section {
  height: 90vh;
  background-color: #05476C;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-attachment: fixed;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.hero-text {
  flex: 1;
  padding-right: 2rem;
  z-index: 2;
}

.hero-title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.hero-subtitle {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.hero-image {
  flex: 1;
  max-width: 50%;
}

.hero-image img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

/* Scroll down icon */
.scroll-down-indicator {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}

.scroll-icon {
  font-size: 2.5rem;
  color: white;
  animation: bounce 1.5s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.scroll-down-indicator:hover .scroll-icon {
  color: #E6B422;
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {
  .hero-section {

    display: flex;
    align-items: center;
    justify-content: center;
   
  }

  .hero-text {
    padding: 0 1rem;
    position: absolute;
    color:#E6B422;
  
  }

  .hero-title {
    font-size: 3.5rem;
  }

  .hero-subtitle {
    font-size: 1.55rem;
    font-weight: 500;
    color:white;
  }

  .hero-image{
   display:none;
  }
  .scroll-down-indicator{
    left:45%;

  }
}
