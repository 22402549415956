/* BackToTop.css */
.back-to-top {
    position: fixed;
    bottom: 30px;
    right: 30px;
    background-color:   #05476C;
    color: white;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: none; /* Hidden by default */
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 24px;
    transition: opacity 0.3s ease;
}

.back-to-top.visible {
    display: flex; /* Show button when visible */
    opacity: 1; /* Make it fully opaque */
}

.back-to-top:not(.visible) {
    opacity: 0; /* Make it transparent */
}
