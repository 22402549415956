.product-header{
    text-align: center;
    color: #05476C;
    margin:50px 0 ;
}
.actiondiv{
   text-align: center;
   display: flex;
   align-items: center;
   justify-content: center;
   margin: 50px 0;
 
  }

  .products h4{
    text-align: center;
    color:black;
    margin: 0 20px;
}