.contact-page{
  background-color: white;
  
}
.contact-page .contact-header{
 margin-top: 50px;
 margin-bottom: 50px;
 color: #05476C;
}

.contact-page .contact-header h1{
  text-align: center;
  font-size: 2.5rem;
  font-weight: 600;
}

.contact-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../assets/ct5.jpg');
  background-size: cover;
  background-position: center;
  padding: 100px;
  color: black;
  background-attachment: fixed;
}

.contact-form-container {
  flex: 2;
  margin-right: 20px;
  background-color: white;
  padding: 100px;
  border-radius: 10px;
}

.contact-details-container {
  flex: 1;
  margin-left: 20px;
  
  padding: 20px;
  border-radius: 10px;
}

.introch {
  font-size: 2.5rem;
  margin-bottom: 50px;
  font-weight: 600;

}
.contact-header h3{
  font-weight: 500;
  color :#05476C;
}
.contact-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.form-group-inline {
  display: flex;
  justify-content: space-between;
}

.form-group label {
  margin-bottom: 5px;
  display: block;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background: #f2f2f2;
}


.contact-submit-btn {
  padding: 10px;
  background-color:#05476C;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-submit-btn:hover {
  background-color: #05476C;
}

.contact-details-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contact-card {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.contact-card-icon {
  background-color:#05476C;
  padding: 15px;
  border-radius: 30px;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-card-icon svg {
  width: 30px;
  height: 30px;
  color: white;
}

.contact-card-content {
  display: flex;
  flex-direction: column;
}

.contact-card-title {
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: bold;
  color:black;
}

.contact-card-info {
  font-size: 16px;
  
  
}
.contact-card-info a{
  text-decoration: none;
  color: black;
  font-weight: 500;
}


@media (max-width: 768px) {
  .contact-page .contact-header h1{
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    
  
  }
  .contact-container {
    flex-direction: column; /* Stack items vertically */
    padding: 20px;
  }

  .contact-form-container {
    order: 1; /* Ensure form container is on top */
    margin-right: 0;
    margin-bottom: 20px;
    padding: 20px;
    width: 100%; /* Full width */
    box-sizing: border-box;
  }

  .contact-details-container {
    order: 2; /* Ensure contact details are below form */
    margin-left: 0;
    padding: 20px;
    width: 100%; /* Full width */
    box-sizing: border-box;
  }

  .introch {
    font-size: 2rem;
    margin-bottom: 30px;
  }

  .form-group-inline {
    flex-direction: column;
  }

  .contact-card {
    flex-direction: column;
    align-items: flex-start;
  }

  .contact-card-icon {
    margin-bottom: 10px;
  }
}